<template>
  <div>

    <h2>Définitions</h2>
    <article class="first">
      <ul>
        <li>
          <b>Conditions Générales :</b> désigne le présent contrat.
        </li>
        <li>
          <b>Application: </b> désigne un ensemble de Services ayant pour objet de
          créer des rencontres virtuelles entre Membres à des fins personnelles et
          non commerciales, L'Application <strong>SPREEDL</strong> est éditée par la
          société <u>Stoney & Praly Sarl</u>.
        </li>
        <li>
          <b>Membre(s) :</b> désigne un (ou l'ensemble des) utilisateur(s) incrit(s)
          et identifié(s) sur l'Application.
        </li>
        <li>
          <b>Service(s) :</b> désigne l'ensemble des fonctionnalités accessibles aux
          Membres sur L'Application.
        </li>
        <li>
          <b>Abonnement :</b> désigne un forfait payant donnant accès à l'ensemble des
          Services de l'Application, renouvelable tacitement par périodes équivalentes à celle initiale,
          sauf indications contraires explicites.
        </li>
      </ul>
    </article>

    <h2>Conditions d'Accès à l'Application</h2>
    <article>
      Tous les équipements (appareils électroniques, connexion internet, etc...)
      permettant l'accès à l'Application sont à la charge exclusive du
      Membre.<br />
      Pour devenir Membre de notre Application, vous devez être âgé(e) d'au
      moins 18 ans et renseigner l'ensemble des données obligatoires du
      formulaire d'inscription de manière complète et sincère.<br /><br />
      Le Membre garantit que les informations fournies sont correctes et
      conformes à son identité. En cas de modification, il s'engage à mettre
      à jour ses données via le formulaire de contact ou les options proposées
      dans l'Application.<br /><br />
      L'Application se réserve le droit de supprimer sans préavis tout compte
      associé à des informations frauduleuses. En cas d'usurpation d'identité,
      le Membre doit en informer immédiatement l'Application. Cette dernière
      n'est pas responsable des actes malveillants commis entre membres (escroqueries, chantages, etc.).<br /><br />
      L'Application peut suspendre temporairement ou modifier l'accès à tout ou partie des Services pour
      maintenance ou autres raisons, sans obligation d'indemnisation.
    </article>

    <h2>Conditions d'Utilisation</h2>
    <article>
      Le membre s'engage à :
      <ul>
        <li>
          <i class="far fa-circle"></i> Faire preuve de courtoisie envers les autres Membres et l'Application.
        </li>
        <li><i class="far fa-circle"></i> Respecter les droits de propriété intellectuelle liés aux contenus fournis par
          l'Application ou les Membres.
        </li>
        <li><i class="far fa-circle"></i> Ne pas publier de contenu diffamatoire, insultant, calomnieux, raciste,
          violent, politique ou pornographique.
        </li>
        <li><i class="far fa-circle"></i> Ne pas divulguer d'informations personnelles (adresse email, numéro de
          téléphone, etc.) appartenant à un autre Membre.
        </li>
        <li><i class="far fa-circle"></i> Ne pas utiliser l'Application à des fins professionnelles ou commerciales
          (prospection, racolage ou prostitution).
        </li>
        <li><i class="far fa-circle"></i> Respecter l'ensemble des lois et règlements en vigueur.
        </li>
      </ul>
      En cas de non-respect, le compte pourra être supprimé définitivement.<br /><br />
      <strong>Obligation de l'Application :</strong><br />
      LL'Application propose un Service de rencontres virtuelles via des outils de communication électroniques. Elle ne
      fournit pas de conseils matrimoniaux ou de courtage en vue d'un mariage ou d'une union stable.<br /><br />
      Les informations partagées par les Membres sont traitées de manière confidentielle. Toutefois, l'Application
      ne peut garantir l'absence de violations de sécurité dues à des incidents techniques ou des cyberattaques.
    </article>

    <h2>Protection des Données Personnelles</h2>
    <article>
      L'Application s'engage à collecter et traiter les données personnelles des Membres conformément au Règlement
      Général sur la Protection des Données (RGPD).<br />
      Les Membres disposent d'un droit d'accès, de rectification et de suppression de leurs données personnelles,
      qu'ils peuvent exercer via le formulaire de contact.<br />
      Les données collectées peuvent être transmises aux autorités compétentes en cas de demande légale.
    </article>

    <h2>Abonnement</h2>
    <article>
      <strong>Modalités de paiement</strong><br />
      Les Abonnements peuvent être réglés par carte bancaire ou d'autres moyens disponibles selon le pays du
      Membre (Google Play Store, Apple Store, etc.). Les tarifs sont affichés TTC. Les Abonnements supérieurs
      à un mois sont payables en une seule fois.<br /><br />
      <strong>Renouvellement d'un Abonnement</strong
      ><br />
      Les Abonnements sont renouvelés tacitement à leur échéance pour une période équivalente, sauf résiliation
      préalable.<br /><br />
      <strong>Résiliation d'un Abonnement</strong><br />
      La résiliation peut être effectuée via l'Application ou le service utilisé pour souscrire l'Abonnement (Google Play, Apple Store, etc.) en respectant les
      conditions de résiliation (durée minimum, etc…). Aucune résiliation ne donne droit à un remboursement partiel.
    </article>

    <h2>Suppression du Membre</h2>
    <article>
      Le Membre peut décider de supprimer son compte à partir de la rubrique
      "Mon Compte" présente sur L'Application. Toutefois, pour pouvoir supprimer
      son compte, le Membre ne doit être en la possession d'un Abonnement non
      résilié. Il est nécessaire de résilier l'Abonnement avant de supprimer le
      compte. Attention: toute suppression du compte est irréversible !
    </article>

    <h2>Propriété Intellectuelle</h2>
    <article>
      L'Application, ainsi que tous ses contenus (nom de domaine, éléments
      graphiques, textes, etc...) est protégé par le droit en vigueur en Europe.
      En aucun cas, le Membre ne peut, sans le consentement écrit de
      l'Application, reproduire, copier, publier, transmettre, communiquer ou
      utiliser de quelque façon que ce soit tout ou partie de ses contenus. Le
      contrevenant s'expose à des sanctions civiles et pénales et notamment aux
      peines prévues aux articles L. 335.2 et L. 343.1 du code de la Propriété
      Intellectuelle.
    </article>

    <h2>Responsabilité</h2>
    <article>

      Le Membre est responsable de l'utilisation de son compte. L'Application décline toute responsabilité
      pour les dommages directs ou indirects liés à l'utilisation du Service.<br /><br />
      L'Application n'est pas responsable des informations incorrectes partagées par un Membre ou des
      incidents survenus lors de rencontres réelles. Elle recommande aux Membres d'organiser
      les premières rencontres dans des lieux publics et d'en informer une personne de confiance.<br /><br />
      L'Application ne peut être tenue responsable des interruptions temporaires ou des dysfonctionnements
      du réseau, des serveurs ou de tout autre événement indépendant de son
      contrôle raisonnable, pouvant affecter l'accès au Service.
    </article>

    <h2>Juridiction et Litiges</h2>
    <article>
      Les présentes CGU sont régies par le droit suisse. Tout litige sera soumis aux juridictions compétentes après tentative de résolution amiable.
    </article>

    <h2>Nous contacter</h2>
    <article>
      Pour toutes questions, veuillez contacter un conseiller par le biais de
      notre formulaire de contact ou par email.<br /><br /><strong>Stoney & Praly Sarl</strong><br />Grande-Rue 16<br />2400
      Le Locle<br />Suisse<br /><br />Email: {{ contactAddress }}
    </article>

    <h2>Rectification légale</h2>
    <article>
      L'Application se réserve le droit de modifier les Conditions Générales à
      tout moment.
    </article>

    <h2 class="mt-3">Attributions</h2>
    <article>
      <b>Icones et illustrations</b><br /><br />
      <ul>
        <li>
          <i class="far fa-circle"></i> FreePik <a href='https://www.freepik.com/'>Flat vector created by freepik - www.freepik.com</a>
        </li>
        <li>
          <i class="far fa-circle"></i> Flaticon <a href="https://www.flaticon.com/" title="high tide icons">Icons created by Freepik - Flaticon</a>
        </li>
      </ul>
    </article>

  </div>
</template>

<script>
export default {
  name: 'TermsEn',
  data: () => ({
    contactAddress: 'customer@spreedl.com'
  })
}
</script>
